import React, { memo } from 'react'
import { css } from '@emotion/react'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { HighlightText } from '~/components/HighlightText'
import {
  globalRed,
  globalGreen,
  globalBlue,
  globalYellow,
  globalGrey,
} from '~/modules/AppLayout/Colors'
import { TimeFromToHint } from '~/modules/options/sharedComponents/ComposedDayRangeSelect/TimeFromToHint'
import OpbsComposedChart from '~/pages/futuresai/opbs/components/OpbsComposedChart'

const Futuresai_Col1 = memo(function Futuresai_Col1() {
  return (
    <div
      css={css`
        ${opbsContainerCss};
        position: relative;
      `}
    >
      <div
        css={css`
          ${flex.h.allCenter};
          & > * {
            margin-right: 8px;
            font-size: 0.75rem;
          }
        `}
      >
        <HighlightText
          content='主力買超'
          background={globalRed.r400}
        />
        <HighlightText
          content='主力賣超'
          background={globalGreen.g400}
        />
        <HighlightText
          content='成本均價'
          background={globalBlue.b900}
        />
        <HighlightText
          content='即時價'
          background={globalYellow.y900}
        />
        <HighlightText
          content='目前損益'
          background={globalGrey.g400}
        />
      </div>
      <div>
        <TimeFromToHint />
      </div>
      <p
        css={css`
          margin: 0;
          position: absolute;
          top: 80px;
        `}
      >
        C+P
      </p>
      <OpbsComposedChart />
    </div>
  )
})

const opbsContainerCss = css`
  ${flex.v.crossCenter};
  width: 100%;
  padding: 0 16px;
`
export default Futuresai_Col1
