import { useState } from 'react'
import { createContainer } from '~/modules/unstated-next-utils/createContainer'

export const VALID_OPTIONS = ['BC', 'SC', 'BP', 'SP']
type OpbsCategory = typeof VALID_OPTIONS[number]

const useOpbsPlQuery = () => {
  const [categories, setCategories] = useState<OpbsCategory[]>(VALID_OPTIONS)
  return {
    state: {
      categories,
    },
    acts: {
      setCategories,
    },
  }
}

/**
 * The state of conditions for filtering the data in OpbsProfitLossChart
 *
 * opbs page only
 * */
export const useOpbsPlQueryState = createContainer(useOpbsPlQuery)
