import { FormControl, FormControlLabel, Checkbox } from '@mui/material'
import { memo } from 'react'
import { useOptionDaysState } from '~/modules/options/shareContainers/useOptionDaysState'

export const SlidingWindowToggle = memo(function SlidingWindowToggle() {
  const filter = useOptionDaysState.useContainer()
  return (
    <FormControl>
      <FormControlLabel
        onChange={(_, checked) => {
          filter.acts.setSlidingWindowMode(checked)
        }}
        value={filter.state.slidingWindowMode}
        control={
          <Checkbox
            color='primary'
            checked={filter.state.slidingWindowMode}
          />
        }
        label='移動窗格模式'
      />
    </FormControl>
  )
})
