import React, { memo, ReactNode } from 'react'
import { css } from '@emotion/react'
import {
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  SelectChangeEvent,
  outlinedInputClasses,
} from '@mui/material'
import { useOptionMQParamState } from '~/modules/options/shareContainers/useOptionMQParamState'

const paramSelectQCss = css`
  width: 80px;
  & .${outlinedInputClasses.input} {
    padding: 12px;
  }
`

const paramSelectDoubleQCss = css`
  width: 128px;
  & .${outlinedInputClasses.input} {
    padding: 12px;
  }
`
const paramSelectMCss = css`
  width: 120px;
  & .${outlinedInputClasses.input} {
    padding: 12px;
  }
`
const ParameterQSelect = memo(function ParameterSelect(props) {
  const { state, acts } = useOptionMQParamState.useContainer()

  return (
    <FormControl
      variant='outlined'
      css={paramSelectQCss}
    >
      <InputLabel>口數篩選</InputLabel>
      <Select
        label='口數篩選'
        value={state.paramQPair[0]}
        onChange={event => {
          const v = event.target.value as number
          const item = state.Q_PAIRS.find(pair => pair.value[0] === v)?.value
          if (item) acts.setParamQPair(item)
        }}
      >
        {state.Q_PAIRS.map(pair => (
          <MenuItem
            key={pair.value[0]}
            value={pair.value[0]}
          >
            {pair.key}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
})

const ParameterMDoubleSelect = memo(function ParameterMDoubleSelect() {
  const { state, acts } = useOptionMQParamState.useContainer()
  const handleChange = (event: SelectChangeEvent<number>, child: ReactNode) => {
    const value = event.target.value as number
    acts.setParameterM(value)
  }

  return (
    <FormControl
      variant='outlined'
      css={paramSelectDoubleQCss}
    >
      <InputLabel>金額</InputLabel>
      <Select
        value={state.parameterM}
        label='金額'
        onChange={handleChange}
      >
        <MenuItem value={0}>不限</MenuItem>
        <MenuItem value={5000}>5000以上</MenuItem>
        <MenuItem value={10000}>10000以上</MenuItem>
        <MenuItem value={30000}>30000以上</MenuItem>
        <MenuItem value={50000}>50000以上</MenuItem>
        <MenuItem value={100000}>100000以上</MenuItem>
        <MenuItem
          disabled
          value={'---'}
        >
          --
        </MenuItem>
        <MenuItem value={-5000}>5000以下</MenuItem>
        <MenuItem value={-10000}>10000以下</MenuItem>
        <MenuItem value={-30000}>30000以下</MenuItem>
        <MenuItem value={-50000}>50000以下</MenuItem>
        <MenuItem value={-100000}>100000以下</MenuItem>
      </Select>
    </FormControl>
  )
})

/**deprecated */
const ParameterMSelect = memo(function ParameterSelect(props) {
  const { state, acts } = useOptionMQParamState.useContainer()

  return (
    <FormControl
      variant='outlined'
      css={paramSelectMCss}
    >
      <InputLabel>金額篩選</InputLabel>
      <Select
        label='金額篩選'
        value={state.parameterM}
        onChange={event => {
          const v = event.target.value as number
          acts.setParameterM(v)
        }}
      >
        <MenuItem value={0}>不限</MenuItem>
        <MenuItem value={5000}>5000 以上</MenuItem>
        <MenuItem value={10000}>10000 以上</MenuItem>
        <MenuItem value={30000}>30000 以上</MenuItem>
        <MenuItem value={50000}>50000 以上</MenuItem>
        <MenuItem value={100000}>100000 以上</MenuItem>
      </Select>
    </FormControl>
  )
})

export default { Q: ParameterQSelect, M: ParameterMSelect, DoubleM: ParameterMDoubleSelect }
