import React, { memo, useMemo } from 'react'
import { ComposedChart } from 'recharts'
import { ResponsiveContainer, XAxis, YAxis } from 'recharts-new'
import useMedia from '~/hooks/useMedia'
import { globalOrange, globalRed } from '~/modules/AppLayout/Colors'

const C_PLUS_P_COLOR = globalOrange.o900
const AT_THE_MONEY_COLOR = globalRed.rA400

/**選擇權分析之即時圖表 ＿買賣C+P力道*/
const OpbsPriceTableChart = memo(function OpbsPriceTableChart(props: {
  title: string
  data: number[]
  minDiffIndex: number
}) {
  const data = useMemo(() => {
    return props.data.map(cp => ({ item: cp ? Math.round(cp) : '-' }))
  }, [props.data])

  return (
    <ResponsiveContainer>
      <ComposedChart
        data={data}
        layout={'vertical'}
        margin={{
          top: 32,
          left: 12,
        }}
        stackOffset='expand'
      >
        <YAxis
          mirror={true}
          tick={p => <CustomizedYAxisLabel tickProps={p} minDiffIndex={props.minDiffIndex} />}
          type='category'
          dataKey='item'
          tickLine={false}
          axisLine={false}
        />

        <XAxis tickLine={false} axisLine={false} tick={p => <></>} />
      </ComposedChart>
    </ResponsiveContainer>
  )
})

const CustomizedYAxisLabel = memo<
  React.PropsWithChildren<{
    minDiffIndex: number
    tickProps: { x: number; y: number; payload: { value: number }; index: number }
  }>
>(function CustomizedYAxisLabel({ tickProps, minDiffIndex }) {
  const { x, y, payload } = tickProps
  const { isPhone } = useMedia()
  const isAtTheMoney = tickProps.index === minDiffIndex
  const Cp = () => (
    <text
      textAnchor='middle'
      x={x + 2}
      y={y}
      fill={isAtTheMoney ? AT_THE_MONEY_COLOR : C_PLUS_P_COLOR}
      fontWeight={isAtTheMoney ? 900 : 500}
      fontSize={isPhone ? 14 : 16}
    >
      {payload.value}
    </text>
  )

  return <Cp />
})
export default OpbsPriceTableChart
