import { useState } from 'react'
import { createContainer } from '~/modules/unstated-next-utils/createContainer'
import dayAPI from '~/utils/dayAPI'
export type TableData = Datum & {
  id: string
  isSelected: boolean
}

export type Datum = {
  name: string
  symbol?: string
  tradeValue: number
  lotCount: number
  avgPrice: number
  close: number
  currentRevenue: number
}

/** 試算opbs倉位 */
export const useTryCalcPosition = () => {
  const [positions, setPositions] = useState<TableData[]>([])

  /**
   * @param data 資料本身
   * @param isCustomized 若是自行輸入而非點選左圖，則不更改lot數量
   * @returns
   */
  const addPosition = (data: Datum, isCustomized?: boolean) => {
    const realLot = isCustomized ? data.lotCount : 1
    const copiedData = { ...data }
    copiedData.lotCount = realLot

    // 已經有該履約商品
    const samePosition = positions.find(p => p.name === copiedData.name)
    if (samePosition) {
      samePosition.lotCount += realLot
      setPositions(pos => [...pos])
      return
    }

    const newData = {
      ...copiedData,
      id: dayAPI().millisecond() + copiedData.name,
      isSelected: true,
    }
    setPositions(pos => [...pos, newData])
  }

  /** @param index 刪除特定index的部位 */
  const deletePosition = (index: number) => setPositions(ps => ps.filter((_, i) => i !== index))

  const selectPosition = (index: number, isChecked: boolean) =>
    setPositions(ps =>
      ps.map((datum, i) => (i === index ? { ...datum, isSelected: isChecked } : datum)),
    )

  const setPositionLot = (index: number, lot: number) =>
    setPositions(ps => ps.map((datum, i) => (i === index ? { ...datum, lotCount: lot } : datum)))

  const setPositionPrice = (index: number, price: number) =>
    setPositions(ps => ps.map((datum, i) => (i === index ? { ...datum, close: price } : datum)))

  return {
    state: {
      positions,
    },
    acts: {
      addPosition,
      deletePosition,
      selectPosition,
      setPositionLot,
      setPositionPrice,
    },
  }
}

export const useTryCalcPositionState = createContainer(useTryCalcPosition)
