import { css } from '@emotion/react'
import { memo } from 'react'
import { globalGrey } from '~/modules/AppLayout/Colors'
import { useOptionDaysState } from '~/modules/options/shareContainers/useOptionDaysState'
import { useOptionsSourceState } from '~/modules/options/shareContainers/useOptionsSourceState'

export const TimeFromToHint = memo(function TimeFromToHint() {
  const filter = useOptionDaysState.useContainer()
  const { state: stateSource } = useOptionsSourceState.useContainer()
  const from = filter.state.fromDay?.format('YYYY/MM/DD HH:mm:ss')
  const to = (
    stateSource.isBackTest || filter.state.slidingWindowMode
      ? filter.state.softToDay
      : filter.state.toDay
  )?.format('YYYY/MM/DD HH:mm:ss')

  return (
    <p
      css={css`
        margin: 4px 0 0;
        font-size: 0.75rem;
        color: ${globalGrey.g600};
      `}
    >
      表格資料區間：{from}&nbsp;~&nbsp;{to}
    </p>
  )
})
