import { FormControl, FormControlLabel, Checkbox } from '@mui/material'
import { memo } from 'react'
import { useOptionDaysState } from '~/modules/options/shareContainers/useOptionDaysState'

export const PreciseToggle = memo(function PreciseToggle() {
  const filter = useOptionDaysState.useContainer()
  return (
    <FormControl>
      <FormControlLabel
        onChange={(e, checked) => filter.acts.setPreciseMode(checked)}
        value={filter.state.preciseMode}
        control={
          <Checkbox
            color='primary'
            checked={filter.state.preciseMode}
          />
        }
        disabled={filter.state.slidingWindowMode}
        label='細部時間選取'
      />
    </FormControl>
  )
})
